import { Component } from '@angular/core';

@Component({
    selector: 'app-page-not-found',
    standalone: true,
    imports: [],
    template: `<div class="container_not">
        <img src="assets/img/error404.png" />
    </div>`,
    styles: `.container_not
    display: flex
    align-items: center
    justify-content: center
    width: 100%
    height: 70vh

    img
      width: 100%
      height: 100%
      object-fit: contain
    `,
})
export class PageNotFoundComponent {}
